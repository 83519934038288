@media (width >= 1023px) {
  #cards {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (width >= 1000px) {
  #cards {
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 30vw;
  }
}

@media (width >= 1500px) {
  #cards {
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 20vw;
  }
}

@media (width <= 800px) {
  .project-overview {
    margin: 3rem 1rem 0;
  }
}

@media (width <= 800px) {
  .project {
    margin: 0 1rem 1rem;
  }
}

section#presentation {
  text-align: center;
  margin: 10rem 0;
}

section#presentation #about {
  margin: 5rem 0;
}

section#presentation p {
  font-size: 1.5rem;
}

#cards {
  grid-template-columns: 1fr;
  gap: 2rem;
  display: grid;
}

@media (width >= 1023px) {
  #cards {
    grid-template-columns: repeat(4, 1fr);
  }
}

section {
  margin: 5rem 0;
}

section > h2 {
  font-size: 2rem;
}

section > h2:before {
  content: "// ";
}

.flag {
  height: 1em;
}

a[data-v-8ff11a] {
  text-decoration: none;
}

.card[data-v-8ff11a] {
  border: 2px solid #000;
  width: 100%;
  height: 100%;
  padding: 1rem;
  font-size: 1.5rem;
  line-height: 2rem;
  position: relative;
}

.card h2 > span[data-v-8ff11a], .card .brief[data-v-8ff11a] {
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  background: #0e0e13e3;
  padding: .5rem 1rem;
  line-height: 1.8em;
}

.card .brief[data-v-8ff11a] {
  line-height: 2em;
}

.card p[data-v-8ff11a] {
  color: #171617;
  background: #d4e0e1;
  border-top: 2px solid #000;
  margin: 0;
  padding: 1.5rem 2rem;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.card img[data-v-8ff11a] {
  object-fit: cover;
  z-index: -1;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.card .brief[data-v-8ff11a] {
  font-style: italic;
}

.card[data-v-8ff11a] {
  overflow: hidden;
}

.card p[data-v-8ff11a] {
  transition: transform .1s;
}

.card:not(:hover) p[data-v-8ff11a] {
  transform: translateY(100%);
}

.card img[data-v-8ff11a] {
  transition: transform .3s;
}

.card:hover img[data-v-8ff11a] {
  transform: scale(1.1);
}

h1 {
  font-size: 4rem;
}

h1 > span {
  position: relative;
}

h1 > span > span {
  opacity: .2;
}

h1 > span > div {
  background: #0b715e;
  width: .1em;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

#cards {
  grid-template-columns: 1fr;
  grid-auto-rows: 60vw;
  gap: 2rem;
  display: grid;
}

@media (width >= 1000px) {
  #cards {
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 30vw;
  }
}

@media (width >= 1500px) {
  #cards {
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 20vw;
  }
}

.project-overview {
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 3rem 3rem 0;
  padding: 1rem;
  display: flex;
  position: relative;
  overflow: hidden;
}

@media (width <= 800px) {
  .project-overview {
    margin: 3rem 1rem 0;
  }
}

.project-overview img {
  object-fit: cover;
  z-index: -1;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.project-overview h1 {
  margin-top: 0;
}

.project-overview h3 + * {
  margin-left: 1rem;
}

.project-overview .right {
  background: #0e0e13e3;
  padding: 1rem;
}

.project-overview .right:empty {
  display: none;
}

.project-overview .right > :first-child h3 {
  margin-top: 0;
}

.project-overview .left h1 > span, .project-overview .left h2 > span {
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  background: #0e0e13e3;
  padding: .5rem 1rem;
  line-height: 1.5em;
}

.project-overview .left h2 > span {
  line-height: 2em;
}

.project {
  color: #171617;
  background: #d4e0e1;
  margin: 0 3rem 3rem;
  padding: 2rem;
  font-family: sans-serif;
  font-size: 1.2rem;
}

@media (width <= 800px) {
  .project {
    margin: 0 1rem 1rem;
  }
}

.project > p:first-child {
  margin-top: 0;
}

.project img {
  object-fit: contain;
  width: 100%;
  max-height: 70vh;
}

.project strong {
  font-weight: bolder;
}

.project .img-desc {
  margin-bottom: 2rem;
  margin-left: 2rem;
  font-size: 1rem;
  font-style: italic;
}

header {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  display: flex;
}

header #home {
  height: 3rem;
}

header nav {
  gap: 2rem;
  display: flex;
}

header nav a {
  border-bottom: 2px solid #0000;
  text-decoration: none;
}

header nav a:hover {
  border-bottom-color: inherit;
  transition: border .2s;
}
/*# sourceMappingURL=index.681b7804.css.map */
